<template>
  <div>
    <svg
      width="15"
      height="15"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.177 13.6311L16.1682 13.6213L16.1589 13.612L13.2271 10.6852L13.2075 10.6656L13.1858 10.6483C13.0803 10.564 12.9463 10.477 12.7909 10.4159C13.3568 9.44969 13.6963 8.31886 13.6963 7.08781C13.6963 3.47428 10.7158 0.5 7.09816 0.5C3.44968 0.5 0.5 3.47584 0.5 7.08781C0.5 10.7306 3.45124 13.6756 7.09816 13.6756C8.30462 13.6756 9.43276 13.3567 10.4167 12.7833C10.4722 12.944 10.565 13.0992 10.7028 13.2368L13.6053 16.1343C14.0794 16.6076 14.8437 16.6387 15.3168 16.1262L16.1214 15.3229C16.6311 14.8541 16.6023 14.1028 16.177 13.6311ZM7.09816 10.3341C5.29192 10.3341 3.84545 8.88891 3.84545 7.08781C3.84545 5.31343 5.29446 3.84146 7.09816 3.84146C8.87508 3.84146 10.3509 5.31597 10.3509 7.08781C10.3509 8.88638 8.87763 10.3341 7.09816 10.3341Z"
        fill="white"
        stroke="#303030"
      />
    </svg>
  </div>
</template>
